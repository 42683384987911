'use client'

import { usePathname } from 'next/navigation'
import {
  TemplateUnauthenticatedProps,
  UnauthenticatedTemplate,
} from 'pattern-library/src/templates/UnauthenticatedTemplate'
import { unauthenticatedPaths } from 'src/utils/route-utils'

export default function Layout({ children }) {
  const pathname = usePathname()

  let footerItems: TemplateUnauthenticatedProps['footerItems'] = defaultFooterItems
  let title = ''
  switch (pathname) {
    case unauthenticatedPaths.login:
      title = 'Login'
      footerItems = loginFooterItems
      break
    case unauthenticatedPaths.register:
      title = 'Sign up'
      break
    case unauthenticatedPaths.resetPassword:
      title = 'Reset Password'
      footerItems = secondaryFooterItems
      break
    case unauthenticatedPaths.newPasswordRequired:
      title = 'New Password Required'
      footerItems = secondaryFooterItems
      break
    case unauthenticatedPaths.resetPassword:
      title = 'Submit New Password'
      footerItems = secondaryFooterItems
      break
    default:
      console.warn(`Layout: unknown pathname ${pathname}`)
  }

  return (
    <UnauthenticatedTemplate title={title} footerItems={footerItems}>
      {children}
    </UnauthenticatedTemplate>
  )
}

const loginFooterItems = [
  {
    label: 'Sign up',
    href: unauthenticatedPaths.register,
  },
  {
    label: 'Reset Password',
    href: unauthenticatedPaths.resetPassword,
  },
]

const defaultFooterItems = [
  {
    label: 'Login',
    href: unauthenticatedPaths.login,
  },
  {
    label: 'Reset Password',
    href: unauthenticatedPaths.resetPassword,
  },
]

const secondaryFooterItems = [
  {
    label: 'Login',
    href: unauthenticatedPaths.login,
  },
  {
    label: 'Sign up',
    href: unauthenticatedPaths.register,
  },
]

import { AppLogo } from 'next-app/app/_components/AppLogo'
import Link from 'next/link'
import { FC } from 'react'

import { Box } from '../basicUI/Box'
import { Flex } from '../basicUI/Flex'
import { Text } from '../basicUI/Text'
import { styled } from '../stitches.config'

export type TemplateUnauthenticatedProps = {
  children: React.ReactNode
  title: string
  footerItems?: {
    label: string
    href: string
  }[]
}

export const UnauthenticatedTemplate: FC<TemplateUnauthenticatedProps> = ({ children, footerItems, title }) => {
  return (
    <>
      <BackgroundContainer>
        <ContentContainter>
          <Flex justify="center">
            <AppLogo requestedHeight={60} reverseThemePalette={false} />
          </Flex>
          <MiddleContainer>
            <div>
              <Text
                variant="h5"
                css={{
                  textAlign: 'center',
                  '@bp2': {
                    paddingTop: '$8',
                    paddingBottom: '$2',
                  },
                }}>
                {title}
              </Text>
              {children}
            </div>
          </MiddleContainer>
          {footerItems && (
            <Box css={{ textAlign: 'center' }}>
              <Box as="hr" css={{ mt: '$6', mb: '$3' }} />
              <Box
                as="ul"
                css={{
                  li: {
                    mx: '$3',
                  },
                }}>
                {footerItems.map(item => (
                  <li key={item.href}>
                    <Link href={item.href}>
                      <Text isLink={true} css={{ color: '$lin' }}>
                        {item.label}
                      </Text>
                    </Link>
                  </li>
                ))}
              </Box>
            </Box>
          )}
        </ContentContainter>
      </BackgroundContainer>
    </>
  )
}

const BackgroundContainer = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  backgroundColor: '$gs2',
  color: '$gs1',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

const ContentContainter = styled('div', {
  position: 'relative',
  zIndex: 1,
  boxSizing: 'border-box',
  width: 'min(450px, 100%)',
  padding: '$10 $6 $6 $6',
  borderRadius: '8px',
  backgroundColor: '$gs1',
  color: '$gs12',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  height: '100%',
  gap: '$6',
  '& > div:nth-child(2)': {
    flex: '1 1 0%',
  },
  ul: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    listStyleType: 'none',
    li: {
      flex: '1 1 auto',
    },
  },
  '@bp2': {
    height: 'initial',
    display: 'block',
  },
})

const MiddleContainer = styled('div', {
  flex: '1 1 0%',
  overflowY: 'auto',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  '& > div': {
    maxHeight: '100%',
    overflowY: 'auto',
  },
})
